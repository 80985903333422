import { Link } from "gatsby";
import * as styles from "./404.module.scss";
import { pageNotFound } from "../components/Icons";

// markup
export default function NotFoundPage() {
    return (
        <main className={styles.notFoundPage}>
            <div className={styles.notFoundHeader}>
                <div>
                    <div className={styles.leftCable}>{pageNotFound.left}</div>
                    <h1>404</h1>
                    <div className={styles.rightCable}>{pageNotFound.right}</div>
                </div>
                <h2>Page not found</h2>
            </div>

            <p>
                We are sorry, the page you requested could not be found.
                <br />
                Please go back to the homepage
                {process.env.NODE_ENV === "development" ? <></> : null}
                <br />
                <Link to="/">Go to home</Link>
            </p>
        </main>
    );
}
